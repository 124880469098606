import React from 'react'
import { Helmet } from "react-helmet"
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import '../../styles/westcity.scss';

const WestCity = () => {
  return (
    <>
    <Helmet
      htmlAttributes={{
          class: 'bd-westcity-sample'
      }}>
        <title>Brolga Digital || WestCity Christian Church</title>
        <link rel="stylesheet" href="https://use.typekit.net/xnh3scp.css"></link>
    </Helmet>

    
        {/* NAVIGATION */}
        <div className='uk-navbar-container uk-light uk-margin-large-bottom'>
            <div className='uk-container uk-container-medium'>
                <nav uk-nav='' className='uk-flex'>
                    <div className='uk-navbar-left'>
                        <Link className="uk-navbar-item uk-logo" to="#"><StaticImage src='../../images/westcity/logo-sample-white-crop.png' height={70} alt='WestCity Christian Church'/></Link>
                    </div>
                    <div className='uk-navbar-right'>
                        <ul className='uk-navbar-nav'>
                            <li><Link to='#'>about</Link></li>
                            <li><Link to='#'>services</Link></li>
                            <li><Link to='#'>contact</Link></li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
      
        <div className='uk-container uk-container-medium uk-margin-large-bottom'>
            <StaticImage src='../../images/westcity/pexels-josh-sorenson-976866.jpg' class='uk-margin-large-bottom bd-hero-img'/>
            
            {/* INTRODUCTION */}
            <div className='uk-container uk-width-3-4 uk-margin-large-bottom'>
                <h3 className='uk-margin-remove-bottom'>Our Vision</h3>
                <p>To be a church that by following the Great Commandment, fulfils the Great Commission, and positively impacts the city around us for the glory of God.</p>

                <h3 className='uk-margin-remove-bottom'>Our Mission</h3>
                <p>Is to be a mission and generational church, committed to moving forward in the Kingdom of God. Growing through sound teaching, training, equipping, and a passion for God and His Word. Expanding through fellowship, care and the support of faithful believers, building faith and love, encouraging hope for each other.</p>

                <h3 className='uk-margin-remove-bottom'>Our Church</h3>
                <p>West City Christian Church is a body of Christians dedicated to spreading God’s word throughout the western suburbs of Melbourne, Victoria. West City Christian is part of the LNI (Leaders Network International) Network which comprises of churches across the world.</p>
            </div>


            {/* INFO BOXES */}
            <div className='uk-grid-large uk-child-width-1-2@s uk-margin-xlarge-top' uk-grid='' >
                <div>
                    <h2>Latest Videos</h2>
                    <hr />
                    <StaticImage src='../../images/westcity/Screen Shot 2022-03-21 at 11.57.14 am.png' class='uk-margin-bottom' />
                    <Link to='#' className='uk-button uk-button-primary uk-align-right'>Watch More</Link>
                </div>
                <div className='bd-service'>
                    <h2>Service Times</h2>
                    <hr />
                    <h3>Sunday</h3>
                    <p>10:30 am - General  Worship</p>
                    <h3>Weekdays</h3>
                    <p>Home bible studies</p>
                    <h3>Easter Times</h3>
                    <p>9:00 am - Good Friday</p>
                    <p>10:30 am - Easter Sunday</p>
                </div>
                <div>
                    <h2>Visit Us</h2>
                    <hr />
                    <StaticImage src='../../images/westcity/Screen Shot 2022-03-21 at 12.43.18 pm.png' class='uk-margin-bottom' />
                    <Link to='#' className='uk-button uk-button-primary uk-align-right'>Get Directions</Link>
                </div>

            </div>
        </div>

        {/* FOOTER */}
        <footer className='uk-padding-large uk-padding-remove-horizontal uk-padding-remove-bottom'>
            <div className='uk-container uk-container-medium '>
                <div className='uk-margin-large-bottom uk-flex-between' uk-grid=''>
                    <div className='uk-width-1-4@s'><StaticImage src='../../images/westcity/logo-sample-colour.png'/></div>
                    <div className='uk-width-1-2@s'>
                        <p>
                            86-88 Central Ave <br />
                            Altona Meadows VIC 3028
                        </p>
                        <p>
                            WestCityCC@outlook.com.au <br />
                            0422706160
                        </p>
                        <ul className='uk-list uk-flex'>
                            <li className='uk-margin-right'><Link to='#' uk-icon="icon: facebook"></Link></li>
                            <li className='uk-margin-right uk-margin-remove-top'><Link to='#' uk-icon="icon: youtube"></Link></li>
                            <li className='uk-margin-right uk-margin-remove-top'><Link to='#' uk-icon="icon: mail"></Link></li>
                            <li className='uk-margin-right uk-margin-remove-top'><Link to='#' uk-icon="icon: location"></Link></li>
                        </ul>
                    </div>
                    <div className='uk-width-1-6@s'>
                        <ul className='uk-nav'>
                            <li><Link to='#' className='uk-flex-center uk-flex-right@s'>About</Link></li>
                            <li><Link to='#' className='uk-flex-center uk-flex-right@s'>Sermons</Link></li>
                            <li><Link to='#' className='uk-flex-center uk-flex-right@s'>Contact</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>

        {/* COPYRIGHT */}
        <div className='bd-copyright uk-text-center uk-padding-small'>
            <p className='uk-margin-remove-bottom'>&copy; <Link to='https://brolgadigital.com.au'>Brolga Digital</Link> {new Date().getFullYear()}</p>
        </div>
    </>
  )
}

export default WestCity